import {appLocalStorage} from '../../../../shared/services';

const initialState = () => {
  return {
    productsToCompare: appLocalStorage.getItem('products-to-compare') || [],
    products: []
  }
};

const state = initialState();

const mutations = {
  ADD_PRODUCT_TO_COMPARE(state, product) {
    state.productsToCompare.push(product);
    appLocalStorage.setItem('products-to-compare', state.productsToCompare);
  },
  REMOVE_PRODUCT_FROM_COMPARE(state, product) {
    state.productsToCompare = state.productsToCompare.filter(s => s.sku !== product.sku);
    appLocalStorage.setItem('products-to-compare', state.productsToCompare);
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  CLEAR_PRODUCTS_TO_COMPARE(state) {
    state.productsToCompare = [];
    appLocalStorage.removeItem('products-to-compare');
  }
};

const actions = {
  setProductsToCompare({state, commit}, payload) {
    if (payload.isCompare) {
      commit('ADD_PRODUCT_TO_COMPARE', payload.product);
      return;
    }
    commit('REMOVE_PRODUCT_FROM_COMPARE', payload.product);

    state.products = state.products.filter(p => p.sku !== payload.product.sku);
  },
  setProductList({commit}, payload) {
    commit('SET_PRODUCTS', payload);
  }
};

const getters = {};

const CatalogCategoryCompare = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CatalogCategoryCompare;
