











import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'header-cart'
})

export default class extends Vue {
  private get quoteItemCount(): number {
    return this.$store.state.CheckoutGlobal.quoteItemCount;
  }
}
