














import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-autocomplete',
  components: {
    'search-autocomplete-list-item': () => import('./AutocompleteListItem.vue')
  }
})

export default class extends Vue {
  private inputQuery: string = '';
  protected autocompleteData: Array<object> = [];
  private _throttle: number;
  private autocompleteLoading: boolean = false;
  private autocompleteRemove: boolean = false;
  private showAutocomplete: boolean = false;

  private fetchAutocompleteData(): void {
    clearTimeout(this._throttle);
    this._throttle = window.setTimeout(() => {
      this.autocompleteRemove = this.inputQuery.length > 0;
      if (this.inputQuery.length > 3) {
        this.autocompleteLoading = true;
        this.$solarClient.get('/api/catalog/search/product/list', {params: {query: this.inputQuery}}).then((response) => {
          this.autocompleteData = response.data.items;
          this.autocompleteLoading = false;

          this.showAutocomplete = true;
        });
      } else {
        this.showAutocomplete = false;
        this.autocompleteLoading = false;
      }
    }, 500);
  }

  private clearSearchInput(): void {
    this.inputQuery = '';
    this.showAutocomplete = false;
    this.autocompleteRemove = false;
  }
}
