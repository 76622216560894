const initialState = () => {
  return {
    productReviews: []
  }
}

const getters = {
  productReviews: state => state.productReviews
};

const mutations = {
  SET_CATEGORY_PRODUCT_REVIEWS(state, payload) {
    state.productReviews = payload;
  }
};

const actions = {
  async getProductReviews({commit}, payload) {
    await this.$solarClient.post('/api/catalog/product-reviews', {
      cacheKey: payload.cacheKey,
      productIds: payload.productIds
    }).then(response => {
      commit('SET_CATEGORY_PRODUCT_REVIEWS', response.data);
    });
  }
};

const state = initialState();

const CatalogCategoryReviews = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CatalogCategoryReviews;
