('.header__nav>ul').dropDown(el => {
  const dropdown = el.getElementsByClassName('--dropdown');
  if (dropdown.length) {
    dropdown[0].style.display = 'flex';
  }
}, el => {
  const dropdown = el.getElementsByClassName('--dropdown');
  if (dropdown.length) {
    dropdown[0].style.display = 'none';
  }
}, 200);

const body = document.body;
let lastScroll = 0;

window.addEventListener('scroll', () => {
  stickyHeader();
});

window.addEventListener('load', () => {
  stickyHeader();
});

function stickyHeader() {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    body.classList.remove('scroll-up');
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
    body.classList.remove('scroll-up');
    body.classList.add('scroll-down');
  } else if (
      currentScroll < lastScroll &&
      body.classList.contains('scroll-down')
  ) {
    body.classList.remove('scroll-down');
    body.classList.add('scroll-up');
  }
  lastScroll = currentScroll;
}

window.addEventListener('load', () => {
  const tabComponents = document.getElementsByClassName('tab__component');
  if (tabComponents.length) {
    for (let i = 0; i < tabComponents.length; i++) {
      const buttons = tabComponents[i].getElementsByClassName('--buttons')[0].getElementsByTagName('button');
      const content = tabComponents[i].getElementsByClassName('--content')[0].getElementsByTagName('section');
      if (buttons.length) {
        for (let b = 0; b < buttons.length; b++) {
          buttons[b].addEventListener('click', () => {
            for (let c = 0; c < buttons.length; c++) {
              buttons[c].classList.remove('--active');
            }
            for (let d = 0; d < content.length; d++) {
              content[d].style.display = content[d].getAttribute('data-tab-content') === buttons[b].getAttribute('data-tab') ? 'block' : 'none';
            }
            buttons[b].classList.add('--active');
          });
        }
      }
    }
  }

  const searchButton = document.getElementById('searchButton');
  if (searchButton) {
    searchButton.addEventListener('click', e => {
      const offScreenSearchElement = document.querySelector('[data-role=offscreen-search]');
      if (offScreenSearchElement) {
        const htmlElement = (offScreenSearchElement as HTMLElement);
        htmlElement.style.display = 'block';
        body.classList.add('no-scroll');
        htmlElement.getElementsByTagName('input')[0].focus();

        setTimeout(() => {
          htmlElement.style.opacity = '1';
        }, 100);

        let closePopup = function (e) {
          console.log(e.target);
          if (e.target.classList.contains('search__modal--overlay') || e.target.classList.contains('--close') || e.target.classList.contains('grid-container') || e.which === 27) {
            body.classList.remove('no-scroll');
            htmlElement.style.opacity = '0';
            setTimeout(() => {
              htmlElement.style.display = 'none';
            }, 100);
          }
        };

        window.addEventListener('click', closePopup, false);
        window.addEventListener('keyup', closePopup, false);
      }
    });
  }
});
